<!--
   RSTI Facial Rekognition Auth UI
-->

<mat-card fxFlex="1 1 auto" ngClass="app-card">

  <div class="mat-card-logo">
    <img mat-card-image src="assets/img/RSTI-Logo2x.png" class="">
  </div>

  
  <mat-card-header>
    <!--<mat-icon mat-card-avatar>lock</mat-icon>-->
    <mat-card-title>Sign In</mat-card-title>
    <mat-card-subtitle>Please enter your email address to begin authentication.</mat-card-subtitle>
  </mat-card-header>
  

  <mat-card-content>

    <form [formGroup]="signInForm" fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-label>Email Address</mat-label>
        <input matInput placeholder="Email Address" formControlName="email" type="email" email name="email"
          required autocomplete="email" />
      </mat-form-field>
    </form>

    <mat-card-actions>
      
      <button [disabled]="signInForm.invalid" mat-stroked-button color="primary" (click)="signIn()">
        <div *ngIf="!(busy | async)">CONTINUE</div>
        <mat-spinner *ngIf="busy | async" [diameter]="35"></mat-spinner>
      </button>
      
    </mat-card-actions>

  </mat-card-content>

  <p *ngIf="errorMessage | async">{{ errorMessage | async }}</p>

  <mat-card-content>
    <div fxFlex></div>
    <p>Not a member yet? <a routerLink='/sign-up' routerLinkActive="active">Sign up</a></p>
  </mat-card-content>

  <mat-card-content>
    <mat-divider  [inset]="true"></mat-divider>
  </mat-card-content>

  <mat-card-content>
    <p>If you are still having trouble signing in contact RSTI at (833) 229-7784.</p>
  </mat-card-content>

</mat-card>