 // rsti Facial Rekognition Auth UI - POC

import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import Storage from "@aws-amplify/storage";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private cognitoUser: CognitoUser & { challengeParam: { email: string } };

  // Get access to window object in the Angular way
  private window: Window;
  constructor(@Inject(DOCUMENT) private document: Document, private httpClient: HttpClient) {
    this.window = this.document.defaultView;
  }

  public async uploadSignUpImage(file: File, email: string){
    await Storage.put(`${email.replace('@','*')}/${file.name}`, file, {
      contentType: file.type,
      metadata: { email: email }
    });
  }

  public async uploadSignInImage(file: File){
      const fileName = `${this.cognitoUser.getUsername().replace('@','*')}/${this.getDateStamp()}/${file.name}`;
      await Storage.put(fileName, file, {
        contentType: file.type,
        bucket: environment.signInBucket
      });
      return fileName
  }

  public async GetWhiteListUrl(){
    return await Storage.get('whitelist/whitelist.json')
  }

  public GetWhiteList(url): Observable<any>{
    return this.httpClient.get(url);
  }

  public async signIn(email: string) {
    this.cognitoUser = await Auth.signIn(email);
  }

  public async signOut() {
    await Auth.signOut();
  }

  public async answerCustomChallenge(answer: string) {
    this.cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, answer);
    return this.isAuthenticated();
  }

  public async getPublicChallengeParameters() {
    if(this.cognitoUser){
      return this.cognitoUser.challengeParam;
    }
    return null;
  }

  public async signUp(email: string, fullName: string, studentId: string) {
    const params = {
      username: email,
      password: this.getRandomString(30),
      attributes: {
        name: fullName,
        "custom:userId": studentId
      }
    };
    await Auth.signUp(params);
  }

  private getRandomString(bytes: number) {
    const randomValues = new Uint8Array(bytes);
    this.window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(this.intToHex).join('');
  }

  private intToHex(nr: number) {
    return nr.toString(16).padStart(2, '0');
  }

  public async isAuthenticated() {
    try {
      await Auth.currentSession();
      return true;
    } catch {
      return false;
    }
  }

  public async getUserDetails() {
    if (!this.cognitoUser) {
      this.cognitoUser = await Auth.currentAuthenticatedUser();
    }
    return await Auth.userAttributes(this.cognitoUser);
  }

  private getDateStamp() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

}
