 // rsti Facial Rekognition Auth UI - POC

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Amplify from 'aws-amplify';

if (environment.production) {
  enableProdMode();
}

Amplify.configure({
  Auth: {
    region: environment.region,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId,
    identityPoolId: environment.identityPoolId
  },
  Storage: { 
    region: environment.region, 
    bucket: environment.signUpBucket
    }
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
