 // rsti Facial Rekognition Auth UI - POC

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../auth.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent {

  public email = new FormControl('');

  signInForm = new FormGroup({
    email: this.email
  });

  private busy_ = new BehaviorSubject(false);
  public busy = this.busy_.asObservable();

  private errorMessage_ = new BehaviorSubject('');
  public errorMessage = this.errorMessage_.asObservable();

  constructor(private router: Router, private auth: AuthService) { }

  public async signIn() {
    this.busy_.next(true);
    this.errorMessage_.next('');
    try {
        this.auth.GetWhiteListUrl().then(
          url => {
            this.auth.GetWhiteList(url).subscribe(
              data =>{
                if(data){
                  var user = data["users"].find(u => u.email === this.email.value);
                  // console.log(user);
                  if(user){
                    this.router.navigate(['/whitelist'], { queryParams: { email: this.email.value } });
                  }
                  else{
                    //not whitelisted, go through cognito sign-in
                    this.auth.signIn(this.email.value).then( data => {
                      this.router.navigate(['/image-auth']);
                    }).catch( err => {
                      console.error(err);
                      if(err.name === 'NotAuthorizedException'){
                        this.errorMessage_.next('Error in user sign up image upload. Please contact an administrator.');
                      }
                      else{
                        this.errorMessage_.next(err.message || err);
                      }
                    });
                  }
                }
                else{
                  console.error("Error getting whitelisted users");
                  this.errorMessage_.next("An error occured. Please try again or contact an administrator.");
                }
              }
            )
          });
    }
    catch (err) {
      console.error(err);
      if(err.name === 'NotAuthorizedException'){
        this.errorMessage_.next('Error in user sign up image upload. Please contact an administrator.');
      }
      else{
        this.errorMessage_.next(err.message || err);
      }
    } finally {
      this.busy_.next(false);
    }
  }
}
