import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-whitelist',
  templateUrl: './whitelist.component.html',
  styleUrls: ['./whitelist.component.css']
})
export class WhitelistComponent implements OnInit {

  public email = new FormControl('');
  public password = new FormControl('');
  public username = new FormControl('');

  loginForm = new FormGroup({
    email: this.email,
    password: this.password,
    username: this.username
  });

  public resetLink = environment.moodleBaseUrl + '/login/forgot_password.php';

  @ViewChild('form', { static: true }) form: ElementRef;
  public apiUrl = environment.moodleBaseUrl+'/login/index.php';

  private busy_ = new BehaviorSubject(false);
  public busy = this.busy_.asObservable();

  private errorMessage_ = new BehaviorSubject('');
  public errorMessage = this.errorMessage_.asObservable();

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.queryParams
    .subscribe(params => {
      this.email.setValue(params.email);
      try{
        this.auth.GetWhiteListUrl().then(
          url => {
            this.auth.GetWhiteList(url).subscribe(
              data =>{
                if(data){
                  var user = data["users"].find(u => u.email === this.email.value);
                  if(user){
                    this.username.setValue(user["student_id"]);
                  }
                  else{
                    this.router.navigate(['/sign-in']);
                  }
                }
              }
            )
          });
      }
      catch(e){
        console.error(e);
        this.router.navigate(['/sign-in']);
      }
    });
  }

  formSubmit(){
    this.auth.signOut();
    this.form.nativeElement.submit();
  }

  async cancel(){
    await this.auth.signOut().then( () =>
      this.router.navigate(['/sign-in'])
    ).catch(
      (err) => console.error(err)
    );
  }
}
