 // rsti Facial Rekognition Auth UI - POC

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-answer-challenge',
  templateUrl: './answer-challenge.component.html',
  styleUrls: ['./answer-challenge.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerChallengeComponent implements OnInit, OnDestroy {

  private errorMessage_ = new BehaviorSubject('');
  public errorMessage = this.errorMessage_.asObservable();

  private busy_ = new BehaviorSubject(false);
  public busy = this.busy_.asObservable();

  private allSubscriptions = new Subscription();

  private email_ = new BehaviorSubject('');
  public email = this.email_.asObservable();

  public showWebCam: boolean = true;
  public imageFile: File;
  
  constructor(private auth: AuthService, private router: Router, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    // Get e-mail address the code was sent to
    // It is a public challenge parameter so let's try it that way
    this.auth.getPublicChallengeParameters()
      .then(param => {
        if(!param){
          this.showWebCam = false;
          this.router.navigate(['/sign-in']);
        }else{
          this.email_.next(param.email);
        }
      })
      .catch(err => {
        this.errorMessage_.next('An error occured.');
        console.error(err);
        this.showWebCam = false;
      });
  }

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
    this.showWebCam = false;
  }

  public imageChangeEvent(file){
    this.imageFile = file;
  }

  public handleWebcamError(error){
    this.errorMessage_.next(error);
  }

  public async submit() {
    try {
      this.errorMessage_.next('');
      this.busy_.next(true);

      if(this.imageFile == null){
        this.errorMessage_.next('Please take a snapshot image.');
        return;
      }

      //upload to S3
      const fileName = await this.auth.uploadSignInImage(this.imageFile);

      if(fileName){
        //submit challenge with name of file uploaded
        const loginSucceeded = await this.auth.answerCustomChallenge(`public/${fileName}`);
        if (loginSucceeded) {
          this.showWebCam = false;
          this.openSnackBar('Successful Facial Recognition!');
          this.router.navigate(['/login']);
        } else {
          this.errorMessage_.next('Your login was unsuccessful.');
          this.showWebCam = false;
        }
      }
    } catch (err) {
      this.errorMessage_.next(err.message || err);
      this.showWebCam = false;
    } finally {
      this.busy_.next(false);
    }
  }

  private openSnackBar(message: string) {
    this._snackBar.open(message, null, {
      duration: 2000,
    });
  }

}
