<!--
   RSTI Facial Rekognition Auth UI
-->

<mat-card fxFlex="1 1 auto" ngClass="app-card">

  <div class="mat-card-logo">
    <img mat-card-image src="assets/img/RSTI-Logo2x.png" class="">
  </div>

  
  <mat-card-header>
    <!--<mat-icon mat-card-avatar>lock</mat-icon>-->
    <mat-card-title>Sign Up</mat-card-title>
    <!--<mat-card-subtitle>Enter your Details and Take a Snapshot.</mat-card-subtitle>-->
  </mat-card-header>
  

  <mat-card-content>
    <form [formGroup]="signUpForm" fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-label>Full Name</mat-label>
        <input matInput placeholder="Full Name" formControlName="fullName" name="name" autocomplete="name" required />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email Address</mat-label>
        <input matInput placeholder="Email Address" formControlName="email" type="email" email name="email"
          autocomplete="email" required />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Username (Student Id)</mat-label>
        <input matInput placeholder="Username (Student Id)" formControlName="studentId" name="studentId"
          autocomplete="studentId" required />
      </mat-form-field>
      <!-- <input type="file" name="image" autocomplete="image" (change)="onFileChanged($event)"/> -->
    </form>

    <app-webcam *ngIf="showWebCam && !(busy | async)" (fileChange)='imageChangeEvent($event)'
      (emitError)='handleWebcamError($event)'></app-webcam>
    <mat-spinner *ngIf="busy | async" [diameter]="35"></mat-spinner>

    <div *ngIf="!(busy | async)">
      <!--<div fxFlex></div>-->
      <button [disabled]="!file || signUpForm.invalid" mat-stroked-button color="primary" (click)="signup()"
        type="submit">
        <div fxFlex></div>
        <div *ngIf="!(busy | async)">SIGN UP</div>
        <div fxFlex></div>
      </button>
      <!--<div fxFlex></div>-->
    </div>

  </mat-card-content>

  <p *ngIf="errorMessage | async">{{ errorMessage | async }}</p>

  <mat-card-content *ngIf="!(busy | async)">
    <div fxFlex></div>
    <p>Already signed up? <a routerLink='/sign-in' routerLinkActive="active">Sign in</a></p>
  </mat-card-content>

</mat-card>
