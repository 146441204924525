<!--
   RSTI Facial Rekognition Auth UI
-->

<mat-card fxFlex="1 1 auto" ngClass="app-card">
  <mat-card-header>
    <!--<mat-icon mat-card-avatar>lock</mat-icon>-->
    <mat-card-title>Complete Sign-In</mat-card-title>
    <mat-card-subtitle>Please take a snapshot to continue.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <app-webcam *ngIf="showWebCam && !(busy | async)" (fileChange)='imageChangeEvent($event)'
      (emitError)='handleWebcamError($event)'></app-webcam>
    <mat-spinner *ngIf="busy | async" [diameter]="35"></mat-spinner>
  </mat-card-content>
  <div *ngIf="!(busy | async)">
    <div fxFlex></div>
    <button [disabled]="!imageFile" mat-stroked-button color="primary" (click)="submit()">
      <div fxFlex></div>
      <div>SIGN IN</div>
      <div fxFlex></div>
    </button>
    <div fxFlex></div>
  </div>
  <div *ngIf="errorMessage | async">
    <div fxFlex></div>
    <p>{{ errorMessage | async }}</p>
    <div fxFlex></div>
    <button mat-stroked-button color="primary" [routerLink]="['/sign-in']">
      <div fxFlex></div>
      <div>TRY AGAIN</div>
      <div fxFlex></div>
    </button>
    <div fxFlex></div>
  </div>
</mat-card>