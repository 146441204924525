 // rsti Facial Rekognition Auth UI - POC

import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivateComponent implements OnInit {

  private userDetails_: BehaviorSubject<any[]> = new BehaviorSubject(undefined);
  public userDetails = this.userDetails_.asObservable();

  public email = new FormControl('');
  public password = new FormControl('');
  public username = new FormControl('');

  loginForm = new FormGroup({
    email: this.email,
    password: this.password,
    username: this.username
  });

  public resetLink = environment.moodleBaseUrl + '/login/forgot_password.php';

  @ViewChild('form', { static: true }) form: ElementRef;
  public apiUrl = environment.moodleBaseUrl+'/login/index.php';

  private busy_ = new BehaviorSubject(false);
  public busy = this.busy_.asObservable();

  private errorMessage_ = new BehaviorSubject('');
  public errorMessage = this.errorMessage_.asObservable();

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
    this.getUserDetails();
  }

  formSubmit(){
    this.auth.signOut();
    this.form.nativeElement.submit();
  }

  async cancel(){
    await this.auth.signOut().then( () =>
      this.router.navigate(['/sign-in'])
    ).catch(
      (err) => console.error(err)
    );
  }

  async resetPassword(){
    await this.auth.signOut().then( () =>
    window.location.href = this.resetLink
  ).catch(
    (err) => console.error(err)
  );
  }

  public async getUserDetails() {
    this.busy_.next(true);
    this.errorMessage_.next('');
    try {
      const userDetails = await this.auth.getUserDetails();
      userDetails.forEach(detail => {
        if(detail.getName() === 'email'){
          this.email.setValue(detail.getValue());
        }
        if(detail.getName() == 'custom:userId'){
          this.username.setValue(detail.getValue());
        }
      });
      if(this.username.value === '' || this.email.value === ''){
        this.errorMessage_.next('Invalid username or email. Pleast contact an administrator.')
      }
      this.userDetails_.next(userDetails);
    } catch (err) {
      this.errorMessage_.next(err.message || err);
    } finally {
      this.busy_.next(false);
    }
  }
}
