import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { WebcamInitError, WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import * as uuid from 'uuid';

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.css']
})
export class WebcamComponent implements OnInit {

  constructor() { }

  public showWebCam: boolean = true;
  public showWebcamImage: boolean = false;

  // latest snapshot
  public webcamImage: WebcamImage = null;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();

  public imageFile: File;
  @Output() fileChange = new EventEmitter();
  @Output() emitError = new EventEmitter();

  ngOnInit() {
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public handleInitError(error: WebcamInitError): void {
    console.error(error);
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      //emit error
      this.emitError.emit('Please allow camera access.');
    }
    else{
      this.emitError.emit('Webcam module is not supported. Please contact an administrator.');
    }
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.showWebCam = false;
    this.showWebcamImage = true;
    this.imageFile = this.dataURItoFile(this.webcamImage.imageAsBase64);
    this.fileChange.emit(this.imageFile);
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public discardSnapshot() {
    this.webcamImage = undefined;
    this.showWebCam = true;
    this.showWebcamImage = false;
    this.fileChange.emit(this.webcamImage);
  }

  private dataURItoFile(dataURI): File {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return this.blobToFile(blob, `${uuid.v4()}.jpeg`);
  }

  private blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;
    //Cast to a File() type
    return <File>theBlob;
  }
  
}
