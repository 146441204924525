 // rsti Facial Rekognition Auth UI - POC

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpComponent {

  email = new FormControl('');
  fullName = new FormControl('');
  studentId = new FormControl('');
  public showWebCam: boolean = true;
  public file: File;

  signUpForm = new FormGroup({
    email: this.email,
    fullName: this.fullName,
    studentId: this.studentId
  });

  private busy_ = new BehaviorSubject(false);
  public busy = this.busy_.asObservable();

  private errorMessage_ = new BehaviorSubject('');
  public errorMessage = this.errorMessage_.asObservable();

  constructor(private router: Router, private auth: AuthService, private _snackBar: MatSnackBar) { }

  // onFileChanged(event) {
  //   this.file = event.target.files[0];
  // }

  public async signup() {
    this.errorMessage_.next('');
    this.busy_.next(true);
    try {
      await this.auth.signUp(this.email.value, this.fullName.value, this.studentId.value);
      await this.auth.uploadSignUpImage(this.file, this.email.value);
      this.openSnackBar('Successful Sign Up!');
      this.router.navigate(['/sign-in']);
    } catch (err) {
      console.log(err);
      this.errorMessage_.next(err.message || err);
      this.file = undefined;
    } finally {
      this.busy_.next(false);
    }
  }

  public imageChangeEvent(file){
    this.file = file;
  }

  public handleWebcamError(error){
    this.errorMessage_.next(error);
  }

  private openSnackBar(message: string) {
    this._snackBar.open(message, null, {
      duration: 2000,
    });
  }
}
