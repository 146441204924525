<!--
   RSTI Facial Rekognition Auth UI
-->

<mat-card fxFlex="1 1 auto" ngClass="app-card">

    <mat-card-header>
        <mat-icon mat-card-avatar>lock</mat-icon>
        <mat-card-title>Complete the Sign-In Process</mat-card-title>
        <mat-card-subtitle>Please enter your password.</mat-card-subtitle>
    </mat-card-header>

     <mat-card-content>
        <form [formGroup]="loginForm" #form ngNoForm action="{{apiUrl}}" method="post" fxLayout="column">
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" readonly required name="email">
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <mat-label>Username (Student Id)</mat-label>
                    <input name="username" formControlName="username" required readonly matInput>
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <mat-label>Password</mat-label>
                    <input name="password" formControlName="password" type="password" required matInput>
                </mat-form-field>
            </div>
            <mat-card-actions>
                <div fxFlex></div>
                <button [disabled]="loginForm.invalid" (click)="formSubmit()" mat-stroked-button color="primary">
                    <div fxFlex></div>
                    <div *ngIf="!(busy | async)">SIGN-IN</div>
                    <mat-spinner *ngIf="busy | async" [diameter]="35"></mat-spinner>
                    <div fxFlex></div>
                </button>
                <button mat-stroked-button color="primary" type="button" (click)="cancel()">
                    <div fxFlex></div>
                    <div>CANCEL</div>
                    <div fxFlex></div>
                </button>
                <div fxFlex></div>
            </mat-card-actions>
        </form>
     </mat-card-content>
    <p *ngIf="errorMessage | async">{{ errorMessage | async }}</p>
    <mat-card-content *ngIf="!(busy | async)">
        <div fxFlex></div>
        <p><a href='{{resetLink}}'>Forgotten your password?</a></p>
    </mat-card-content>
</mat-card>