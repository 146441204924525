<div class="webcam-wrapper">    

  <div *ngIf="showWebCam">
    <webcam [height]="460" [width]="460" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
      [imageQuality]="1" (initError)="handleInitError($event)" *ngIf="showWebCam">
    </webcam>
    <br />
    <mat-card-actions>
      <div fxFlex></div>
      <button mat-stroked-button color="primary" (click)="triggerSnapshot();">
        <div fxFlex></div>
        <div>TAKE SNAPSHOT</div>
        <div fxFlex></div>
      </button>
      <button mat-stroked-button color="primary" [routerLink]="['/sign-in']">
        <div fxFlex></div>
        <div>CANCEL</div>
        <div fxFlex></div>
      </button>
      <div fxFlex></div>
    </mat-card-actions>
  </div>
  <div *ngIf="showWebcamImage && webcamImage">
    <div class="snapshot" *ngIf="webcamImage">
      <img [src]="webcamImage.imageAsDataUrl" />
    </div>
    <br />
    <mat-card-actions>
      <div fxFlex></div>
      <button mat-stroked-button color="primary" (click)="discardSnapshot();">
        <div fxFlex></div>
        <div>DISCARD SNAPSHOT</div>
        <div fxFlex></div>
      </button>
      <button mat-stroked-button color="primary" [routerLink]="['/sign-in']">
        <div fxFlex></div>
        <div>CANCEL</div>
        <div fxFlex></div>
      </button>
      <div fxFlex></div>
    </mat-card-actions>
  </div>

  <mat-card-content>
    <mat-divider  [inset]="true"></mat-divider>
  </mat-card-content>

  <mat-card-content>
    <strong>Tips:</strong>
    <ul>
      <li>Verify that camera is enabled and not in use by other applications</li>
      <li>Look directly at the camera with ample forward-facing light</li>
      <li>Make sure you are the only person in the cameras view</li>
    </ul>
    <p>If you are still having trouble contact RSTI at (833) 229-7784.</p>
  </mat-card-content>

</div>